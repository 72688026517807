import Aos from "aos";
import { useEffect } from "react";
import taskManagerPic from "../../pictures/task-manger.jpeg"
import countryWikiPic from "../../pictures/country-wiki.png"
import pathFinderPic from "../../pictures/pathfinder.png"
import moneyHeadPic from "../../pictures/moneyhead.png"

function Projects() {
  useEffect(() => {
    Aos.init({ duration: 1650, once: true })
  })
  return (

    <div>
      <section id="projects">
        <section className="md:h-full flex items-center text-bg-base-100">
          <div className="container px-5 py-24 mx-auto">
            <div className="text-center mb-12">
              <h1 data-aos="fade-up" className="text-3xl lg:text-5xl md:text-4xl sm:text-3xl text-bg-base-100 font-bold">
                Projects
              </h1>
            </div>
            <div data-aos="fade-up" className="flex flex-wrap -m-4">
              <div className="p-4 sm:w-1/2 lg:w-1/3">
                <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img
                    className="lg:h-72 md:h-48 w-full object-cover object-center"
                    src={taskManagerPic}
                    alt="blog"
                  />
                  <div className="p-6 hover:text-white transition duration-300 ease-in">
                    <h1 className="text-2xl font-semibold mb-3">
                      Task Manager
                    </h1>
                    <h2>
                      <div className="badge inline-block bg-primary text-white">
                        Java
                      </div>
                      <div className="badge bg-amber-400 text-white inline-block">
                        Firebase Database & Authentication
                      </div>
                      <div className="badge bg-green-600  text-white inline-block">
                        Android Studio
                      </div>
                    </h2>
                    <p className="leading-relaxed mb-3">
                      Android application where users can manage their tasks
                    </p>
                    <div className="flex items-center flex-wrap ">
                      <button onClick={(e) => {
                        e.preventDefault();
                        window.open('https://github.com/nihad6690/Task-Manager-Android-App', '_blank');
                      }} className="text-bg-base-100 inline-flex items-center lg:ml-auto md:ml-auto ml-auto leading-none text-sm pr-3 py-1 border-r-2 btn btn-primary">
                        Github
                        <a href="/#">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 512 512"
                            className="fill-bg-base-100 ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M312 473.421C312 474.728 313.237 475.682 314.499 475.343C409.841 449.673 480 362.773 480 259.521C480 136.074 379.712 36 256 36C132.288 36 32 136.074 32 259.521C32 362.773 102.159 449.673 197.501 475.343C198.763 475.682 200 474.728 200 473.421V424.569C181.564 431.733 156.501 430.701 139.332 417.853C128.449 409.708 123.783 399.231 119.504 389.626C115.224 380.018 111.333 371.282 101.997 366.624C84.7863 358.039 88 347.996 106.667 352.653C125.322 357.307 132.409 367.912 143.976 385.22L143.997 385.251C152.208 397.539 182.079 402.621 201.856 390.991C203.81 379.807 207.821 368.899 216.053 359.565C137.121 349.166 116 306.239 116 254.864C116 231.725 123.856 210.299 138.728 192.787C132.114 168.418 133.8 146.585 142.17 129.288C164.464 127.79 186.022 137.283 204.205 154.024C219.658 149.97 236.969 147.76 256 147.76C275.031 147.76 292.342 149.97 307.795 154.024C325.978 137.283 347.536 127.79 369.83 129.288C378.2 146.585 379.886 168.418 373.271 192.787C388.144 210.299 396 231.725 396 254.864C396 306.239 374.879 349.166 295.947 359.565C312 377.767 312 401.95 312 422.505V473.421Z" />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 sm:w-1/2 lg:w-1/3">
                <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img
                    className="lg:h-72 md:h-48 w-full object-cover object-center"
                    src={countryWikiPic}
                    alt="blog"
                  />
                  <div className="p-6 hover:text-white transition duration-300 ease-in">
                    <h1 className="text-2xl font-semibold mb-3">
                      Country-Wiki
                    </h1>
                    <h2>
                      <div className="badge bg-red-600 text-white">HTML</div>
                      <div className="badge inline-block bg-blue-600 text-whitem-2">
                        CSS
                      </div>
                      <div className="badge bg-amber-400 text-white first-letter:">
                        Javascript
                      </div>
                      <div className="badge bg-green-500  text-white">
                        Node.js
                      </div>
                      <div className="badge bg-green-600  text-white">
                        Leaflet.js
                      </div>
                    </h2>

                    <p className="leading-relaxed mb-3">
                      Web application that finds country information
                    </p>
                    <div className="flex items-center flex-wrap ">
                      <button onClick={(e) => {
                        e.preventDefault();
                        window.open('https://github.com/nihad6690/countryWiki', '_blank');
                      }} className="text-bg-base-100 inline-flex items-center lg:ml-auto md:ml-auto ml-auto leading-none text-sm pr-3 py-1 border-r-2 btn btn-primary">
                        Github
                        <a href="/#">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 512 512"
                            className="fill-bg-base-100 ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M312 473.421C312 474.728 313.237 475.682 314.499 475.343C409.841 449.673 480 362.773 480 259.521C480 136.074 379.712 36 256 36C132.288 36 32 136.074 32 259.521C32 362.773 102.159 449.673 197.501 475.343C198.763 475.682 200 474.728 200 473.421V424.569C181.564 431.733 156.501 430.701 139.332 417.853C128.449 409.708 123.783 399.231 119.504 389.626C115.224 380.018 111.333 371.282 101.997 366.624C84.7863 358.039 88 347.996 106.667 352.653C125.322 357.307 132.409 367.912 143.976 385.22L143.997 385.251C152.208 397.539 182.079 402.621 201.856 390.991C203.81 379.807 207.821 368.899 216.053 359.565C137.121 349.166 116 306.239 116 254.864C116 231.725 123.856 210.299 138.728 192.787C132.114 168.418 133.8 146.585 142.17 129.288C164.464 127.79 186.022 137.283 204.205 154.024C219.658 149.97 236.969 147.76 256 147.76C275.031 147.76 292.342 149.97 307.795 154.024C325.978 137.283 347.536 127.79 369.83 129.288C378.2 146.585 379.886 168.418 373.271 192.787C388.144 210.299 396 231.725 396 254.864C396 306.239 374.879 349.166 295.947 359.565C312 377.767 312 401.95 312 422.505V473.421Z" />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 sm:w-1/2 lg:w-1/3">
                <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img
                    className="lg:h-72 md:h-48 w-full object-cover object-center"
                    src={moneyHeadPic}
                    alt="blog"
                  />
                  <div className="p-6 hover:text-white transition duration-300 ease-in">
                    <h1 className="text-2xl font-semibold mb-3">
                      Money Head
                    </h1>
                    <h2>
                      <div className="badge bg-green-500  text-white">
                        Node.js
                      </div>
                      <div className="badge bg-green-600  text-white">
                        MongoDB
                      </div>
                      <div className="badge bg-grey-400 text-white">Express.js</div>
                      <div className="badge bg-blue-400 text-white first-letter:">
                        React
                      </div>
                      <div className="badge inline-block bg-blue-600 text-whitem-2">
                        CSS
                      </div>
                    </h2>

                    <p className="leading-relaxed mb-3">
                      Node.js application where you can manage your money
                    </p>
                    <div className="flex items-center flex-wrap ">
                      <button onClick={(e) => {
                        e.preventDefault();
                        window.open('https://github.com/nihad6690/Money-Manager', '_blank');
                      }} className="text-bg-base-100 inline-flex items-center lg:ml-auto md:ml-auto ml-auto leading-none text-sm pr-3 py-1 border-r-2 btn btn-primary">
                        Github
                        <a href="/#">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 512 512"
                            className="fill-bg-base-100 ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M312 473.421C312 474.728 313.237 475.682 314.499 475.343C409.841 449.673 480 362.773 480 259.521C480 136.074 379.712 36 256 36C132.288 36 32 136.074 32 259.521C32 362.773 102.159 449.673 197.501 475.343C198.763 475.682 200 474.728 200 473.421V424.569C181.564 431.733 156.501 430.701 139.332 417.853C128.449 409.708 123.783 399.231 119.504 389.626C115.224 380.018 111.333 371.282 101.997 366.624C84.7863 358.039 88 347.996 106.667 352.653C125.322 357.307 132.409 367.912 143.976 385.22L143.997 385.251C152.208 397.539 182.079 402.621 201.856 390.991C203.81 379.807 207.821 368.899 216.053 359.565C137.121 349.166 116 306.239 116 254.864C116 231.725 123.856 210.299 138.728 192.787C132.114 168.418 133.8 146.585 142.17 129.288C164.464 127.79 186.022 137.283 204.205 154.024C219.658 149.97 236.969 147.76 256 147.76C275.031 147.76 292.342 149.97 307.795 154.024C325.978 137.283 347.536 127.79 369.83 129.288C378.2 146.585 379.886 168.418 373.271 192.787C388.144 210.299 396 231.725 396 254.864C396 306.239 374.879 349.166 295.947 359.565C312 377.767 312 401.95 312 422.505V473.421Z" />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 sm:w-1/2 lg:w-1/3">
                <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img
                    className="lg:h-72 md:h-48 w-full object-cover object-center"
                    src={pathFinderPic}
                    alt="blog"
                  />
                  <div className="p-6 hover:text-white transition duration-300 ease-in">
                    <h1 className="text-2xl font-semibold mb-3">Path Finder</h1>
                    <h2>
                      <div className="badge inline-block bg-red-600 text-white">
                        HTML
                      </div>
                      <div className="badge bg-blue-600 text-white inline-block">
                        CSS
                      </div>
                      <div className="badge bg-amber-400 text-white inline-block">
                        Javascript
                      </div>
                    </h2>
                    <p className="leading-relaxed mb-3">
                      Web application that finds the path between two objects
                    </p>
                    <div className="flex items-center flex-wrap ">
                      <button onClick={(e) => {
                        e.preventDefault();
                        window.open('https://github.com/nihad6690/pathfinder', '_blank');
                      }} className="text-bg-base-100 inline-flex items-center lg:ml-auto md:ml-auto ml-auto leading-none text-sm pr-3 py-1 border-r-2 btn btn-primary">
                        Github
                        <a href="/#">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 512 512"
                            className="fill-bg-base-100 ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M312 473.421C312 474.728 313.237 475.682 314.499 475.343C409.841 449.673 480 362.773 480 259.521C480 136.074 379.712 36 256 36C132.288 36 32 136.074 32 259.521C32 362.773 102.159 449.673 197.501 475.343C198.763 475.682 200 474.728 200 473.421V424.569C181.564 431.733 156.501 430.701 139.332 417.853C128.449 409.708 123.783 399.231 119.504 389.626C115.224 380.018 111.333 371.282 101.997 366.624C84.7863 358.039 88 347.996 106.667 352.653C125.322 357.307 132.409 367.912 143.976 385.22L143.997 385.251C152.208 397.539 182.079 402.621 201.856 390.991C203.81 379.807 207.821 368.899 216.053 359.565C137.121 349.166 116 306.239 116 254.864C116 231.725 123.856 210.299 138.728 192.787C132.114 168.418 133.8 146.585 142.17 129.288C164.464 127.79 186.022 137.283 204.205 154.024C219.658 149.97 236.969 147.76 256 147.76C275.031 147.76 292.342 149.97 307.795 154.024C325.978 137.283 347.536 127.79 369.83 129.288C378.2 146.585 379.886 168.418 373.271 192.787C388.144 210.299 396 231.725 396 254.864C396 306.239 374.879 349.166 295.947 359.565C312 377.767 312 401.95 312 422.505V473.421Z" />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default Projects;
