import Lottie from "react-lottie";
import Typewriter from "typewriter-effect";
import React, { useEffect } from "react";
import animationData from "../../animations/computer-guy.json";
import Aos from "aos";
import "aos/dist/aos.css";

function Hero() {
  useEffect(() => {
    Aos.init({ duration: 1650, once: true });
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };



  return (
    <div>
      <div className="hero min-h-screen bg-base-200" id="hero">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <Lottie width="500" options={defaultOptions} />
          <div data-aos="fade-up">
            <h1 className="aos-init aos-animate text-2xl 2xl:text-3xl xl:text-3xl lg:text-3xl">
              Hi there! My name is
            </h1>
            <h1 className="text-5xl 2xl:text-6xl xl:text-6xl lg:text-6xl md:text-5xl sm:text-5xl font-bold text-secondary">
              Dhruvesh Nihalani
            </h1>
            <div data-aos="ease-out" className="text-3xl 2xl:text-4xl xl:text-4xl lg:text-4xl inline">
              I'm a{" "}
            </div>
            <div
              data-aos="ease-out"
              className="text-3xl 2xl:text-4xl xl:text-4xl lg:text-4xl font-bold text-secondary inline-block"
            >
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 100,
                  strings: [
                    "Software Engineer",
                    "Problem Solver",
                    "Innovator",
                    "Leader",
                    "Continuous Learner",
                  ],
                }}
              />
            </div>
            <p data-aos="ease-out" className="text-lg py-6">
              I am a second year Computer Science student at the University of
              Guelph with a passion for software development{" "}
            </p>
            <div className="inline-block">
              <a href="https://github.com/nihad6690" rel="noreferrer" target="_blank" data-aos="ease-out" className="inline-block mr-3">
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 512 512"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hover:fill-white"

                >
                  <path d="M312 473.421C312 474.728 313.237 475.682 314.499 475.343C409.841 449.673 480 362.773 480 259.521C480 136.074 379.712 36 256 36C132.288 36 32 136.074 32 259.521C32 362.773 102.159 449.673 197.501 475.343C198.763 475.682 200 474.728 200 473.421V424.569C181.564 431.733 156.501 430.701 139.332 417.853C128.449 409.708 123.783 399.231 119.504 389.626C115.224 380.018 111.333 371.282 101.997 366.624C84.7863 358.039 88 347.996 106.667 352.653C125.322 357.307 132.409 367.912 143.976 385.22L143.997 385.251C152.208 397.539 182.079 402.621 201.856 390.991C203.81 379.807 207.821 368.899 216.053 359.565C137.121 349.166 116 306.239 116 254.864C116 231.725 123.856 210.299 138.728 192.787C132.114 168.418 133.8 146.585 142.17 129.288C164.464 127.79 186.022 137.283 204.205 154.024C219.658 149.97 236.969 147.76 256 147.76C275.031 147.76 292.342 149.97 307.795 154.024C325.978 137.283 347.536 127.79 369.83 129.288C378.2 146.585 379.886 168.418 373.271 192.787C388.144 210.299 396 231.725 396 254.864C396 306.239 374.879 349.166 295.947 359.565C312 377.767 312 401.95 312 422.505V473.421Z" />
                </svg>
              </a>
              <a href="https://www.linkedin.com/in/dhruvesh-nihalani/" rel="noreferrer" target="_blank" data-aos="ease-out" className="inline-block mr-3 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-2 -2 24 24"
                  width="28"
                  fill="currentColor"
                  className="hover:fill-white"
                >
                  <path d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457-.592 0-.945.398-1.1.784-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066 1.41 0 2.468.922 2.468 2.902zM6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115-.014-.632-.465-1.114-1.199-1.114zm-1.086 9.556h2.144V8.38H5.127v6.447z"></path>
                  <path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path>
                </svg>
              </a>
              <a href="mailto:dnihalan@uoguelph.ca" rel="noreferrer" target="_blank" data-aos="ease-out" className="inline-block mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-7 h-7 hover:stroke-white"

                >
                  <path d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
