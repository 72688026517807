import { useEffect, useState } from "react";
import { Link } from "react-scroll";

function Navbar() {
  const [navbar, setNavBar] = useState(false);
  useEffect(() => {
    const handleShadow = () => {
      if (window.scrollY >= 90) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    };
    window.addEventListener('scroll', handleShadow);
  }, []);
  return (
    <div>
      <nav
        className={
          navbar
            ? "bg-neutral fixed w-full z-10 top-0 flex items-center p-3 ease-in duration-300"
            : "bg-[#0F1729] fixed w-full z-10 top-0 flex items-center p-3 ease-out duration-700"
        }
      >
        <a data-aos="fade-right" data-aos-duration="850" className="btn btn-ghost normal-case text-xl">Dhruvesh Nihalani</a>

        <button data-aos="fade-left"  data-aos-duration="850"
          className="inline-flex p-3 rounded lg:hidden md:hidden ml-auto hover:text-white outline-none nav-toggler"
          data-target="#navigation"
        >
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn m-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 stroke-current"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </label>
            <ul
              tabIndex={0}
              className="fixed dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link to="hero" smooth="true" duration={1000}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="about" smooth="true" duration={1000}>
                  About
                </Link>
              </li>
              <li>
                <Link to="projects" smooth="true" duration={1000}>
                  Projects
                </Link>
              </li>
              <li>
                <Link to="experience" smooth="true" duration={1000}>
                  Experience
                </Link>
              </li>
              <li>
                <Link to="contact" smooth="true" duration={1000}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </button>

        <div data-aos="fade-left"  data-aos-duration="850"
          className="hidden text-lg top-navbar w-full lg:inline-flex lg:flex-grow lg:w-auto md:inline-flex md:flex-grow md:w-auto"
          id="navigation"
        >
          <div
            className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start flex flex-col lg:h-auto
          md:inline-flex md:flex-row md:ml-auto md:w-auto md:items-center md:h-auto
          "
          >
            <Link
              className="cursor-pointer lg:inline-flex lg:w-auto w-full active:bg-primary active:text-primary-content px-3 py-2 rounded-md text-bg-base-100 items-center justify-center hover:bg-neutral-focus"
              to="hero"
              smooth="true"
              duration={1000}
            >
              Home
            </Link>

            <Link
              className="cursor-pointer lg:inline-flex lg:w-auto w-full active:bg-primary active:text-primary-content px-3 py-2 rounded-md text-bg-base-100 items-center justify-center hover:bg-neutral-focus"
              to="about"
              smooth="true"
              duration={1000}
            >
              About
            </Link>

            <Link
              className="cursor-pointer lg:inline-flex lg:w-auto w-full active:bg-primary active:text-primary-content px-3 py-2 rounded-md text-bg-base-100 items-center justify-center hover:bg-neutral-focus"
              to="projects"
              smooth="true"
              duration={1000}
            >
              Projects
            </Link>

            <Link
              className="cursor-pointer lg:inline-flex lg:w-auto w-full active:bg-primary active:text-primary-content px-3 py-2 rounded-md text-bg-base-100 items-center justify-center hover:bg-neutral-focus"
              to="experience"
              smooth="true"
              duration={1000}
            >
              Experience
            </Link>

            <Link
              className="cursor-pointer lg:inline-flex lg:w-auto w-full active:bg-primary active:text-primary-content px-3 py-2 rounded-md text-bg-base-100 items-center justify-center hover:bg-neutral-focus"
              to="contact"
              smooth="true"
              duration={1000}
            >
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
