import Navbar from './components/navbar/navbar'
import Hero from'./components/hero/hero'
import About from './components/about/about'
import Projects from './components/projects/projects';
import Experience from './components/experience/experience';
import Contact from './components/contact/contact'
import Footer from './components/footer/footer';

function App() {
  
  return (
    <div>
      <html data-theme="night">
        <Navbar />
        <Hero/>
        <About/>
        <Projects/>
        <Experience/>
        <Contact />
        <Footer/>
      </html>



    </div>



  );
}

export default App;
