import Aos from "aos";
import { useEffect, useState, useRef } from "react";
import emailjs from 'emailjs-com';




function Contact() {
  const form = useRef();
  const sendEmail = (event) => {
    event.preventDefault();
    let userID = ''
    const data = new FormData(form.current);
    const json = Object.fromEntries(data.entries());
    if (json.name && json.email && json.message) {
      userID = 'ojSC6M7uQGmPfDAOB'
    }
    else{
      setFail(true)
      setSuccess(false)
    }
    emailjs.sendForm('service_9u9psdg', 'template_39d5q2b', form.current, userID)
      .then((result) => {
        console.log("hello")
        console.log(result.text);
        setSuccess(true)
        setFail(false)
      }, (error) => {
        console.log(error.text);
      });
  };


  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);


  const handleNameInputChange = (event) => {
    setValues({ ...values, name: event.target.value })
  }
  const handleEmailInputChange = (event) => {
    setValues({ ...values, email: event.target.value })
  }
  const handleMessageInputChange = (event) => {
    setValues({ ...values, message: event.target.value })
  }

  
  useEffect(() => {
    Aos.init({ duration: 1000, once: true})
  })
  return (
    <div id='contact'>

      <div data-aos="fade-up">
        <h1 className="text-3xl lg:text-5xl md:text-4xl sm:text-3xl grid place-items-center mt-10 font-bold">
          Get in touch
        </h1>



        <div className="card lg:card-side">
          <div data-aos="fade-up" className="card-body grid place-items-center">
            <form id={form} ref={form} onSubmit={sendEmail}>
              <input
                onChange={handleNameInputChange}
                value={values.name}
                type="text"
                placeholder="Name"
                className="input input-bordered input-secondary w-full max-w-3xl m-2"
                name="name"
              />
              <input
                onChange={handleEmailInputChange}
                value={values.email}
                type="text"
                placeholder="Email"
                className="input input-bordered input-secondary w-full max-w-3xl m-2"
                name="email"
              />
              <textarea
                name="message"
                onChange={handleMessageInputChange}
                value={values.message}
                className="textarea w-full h-full max-w-3xl textarea-secondary m-2" rows={10} placeholder="Message"
              ></textarea >


              <div className="card-actions justify-center" >
                <button type="submit" className="btn btn-primary">SEND MESSAGE<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" className="feather feather-send ml-4"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg></button>
              </div>
            </form>
            {fail ? <div data-aos="fade-down" data-aos-duration="500" className="alert alert-error shadow-lg mt-2">
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>Please enter all of the information correctly.</span>
              </div>
            </div> : null}
            {success ? <div data-aos="fade-down"  data-aos-duration="500" className="alert alert-success shadow-lg mt-2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>Message sent successfully! Thank you for reaching out, I will get back to you as soon as possible! </span>
              </div>
            </div> : null}

            
          </div>
        </div>
      </div>

    </div>

  );
}

export default Contact
