import Aos from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Pagination } from "swiper";
import { useEffect } from "react";

function Experience() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };
  useEffect(() => {
    Aos.init({ duration: 1650, once: true });
  });
  return (
    <div id="experience">
      <h1 data-aos="fade-up" className="grid place-items-center text-3xl lg:text-5xl md:text-4xl sm:text-3xl text-bg-base-100 font-bold mb-10 ">
        Experience
      </h1>
      <Swiper data-aos="fade-up"
        pagination={pagination} modules={[Pagination]} className="mySwiper"
      >
        <SwiperSlide>
          <div className="ml-4 mr-4 card lg:card-fit 2xl:h-72 xl:h-80 lg:h-80 md:h-[24rem] sm:h-[32rem] bg-neutral shadow-xl lg:ml-7 lg:mr-7 xl:ml-14 xl:mr-14 2xl:ml-28 2xl:mr-28">
            <div className="card-body">
              <h2 className="text-xl card-title 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl mt-2 ml-4 mb-2">Coding Tutor</h2>
              <ul className="text-lg list-disc ml-16 2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg sm:text-lg">
                <li>
                  Tutored 10+ students in several coding languages such as Java, Python and JavaScript.
                </li>
                <li>
                  Assisted students to understand coding fundamentals and walked through practice problems to get a grasp of the
                  concepts.
                </li>
                <li>
                  Guided and provided students assistance to implement that knowledge into projects of their own.
                </li>
              </ul>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="ml-4 mr-4 card lg:card-fit 2xl:h-72 xl:h-80 lg:h-80 md:h-[24rem] sm:h-[32rem] bg-neutral shadow-xl lg:ml-7 lg:mr-7 xl:ml-14 xl:mr-14 2xl:ml-28 2xl:mr-28">
            <div className="card-body">
              <h2 className="text-xl card-title 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl mt-2 ml-4 mb-2">Tim Hortons</h2>
              <ul className="text-lg list-disc ml-16 2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg sm:text-lg">
                <li>
                  Consistently provided exceptional customer service and accurately fulfilled all position responsibilities.
                </li>
                <li>
                  Unloaded delivery trucks and restocked store shelves in a timely manner.
                </li>
                <li>
                  Participated in the development and expansion of the monthly specials program.
                </li>
              </ul>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Experience;
